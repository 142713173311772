import React from 'react';
import { useParams } from 'react-router-dom';
import StockDetail from '../components/StockDetail';

function StockPage() {
  const { id } = useParams();
  return (
    <div>
      <h1>Stock Details</h1>
      <StockDetail id={id} />
    </div>
  );
}

export default StockPage;
