import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

function StockDetail({ id }) {
  const [stock, setStock] = useState(null);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    const fetchStock = async () => {
      try {
        const response = await fetch(`http://localhost:5000/api/stocks/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setStock(data);
      } catch (error) {
        console.error('Failed to fetch stock:', error);
      }
    };

    if (token) {
      fetchStock();
    }
  }, [id, token]);

  if (!stock) return <div>Loading...</div>;

  return (
    <div>
      <h2>{stock.name}</h2>
      <p>Price: ${stock.price}</p>
    </div>
  );
}

export default StockDetail;
