import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import StockPage from './pages/StockPage';
import WatchlistPage from './pages/WatchlistPage';
import Header from './components/Header';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/stock/:id" element={<StockPage />} />
            <Route path="/watchlist" element={<WatchlistPage />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
