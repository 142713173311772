import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';

function Watchlist() {
  const [stocks, setStocks] = useState([]);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    const fetchWatchlist = async () => {
      try {
        const response = await fetch('http://localhost:5000/api/watchlist', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setStocks(data);
      } catch (error) {
        console.error('Failed to fetch watchlist:', error);
      }
    };

    if (token) {
      fetchWatchlist();
    }
  }, [token]);

  return (
    <div>
      {stocks.map(stock => (
        <div key={stock.id}>
          <Link to={`/stock/${stock.id}`}>{stock.name}</Link>
          <p>Price: ${stock.price}</p>
        </div>
      ))}
    </div>
  );
}

export default Watchlist;
