import React from 'react';
import Watchlist from '../components/Watchlist';

function WatchlistPage() {
  return (
    <div>
      <h1>Your Watchlist</h1>
      <Watchlist />
    </div>
  );
}

export default WatchlistPage;
