import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1 style={{ color: '#333', marginBottom: '20px' }}>
        Welcome to Stock Monitor
      </h1>
      
      <div style={{ 
        backgroundColor: '#f5f5f5', 
        padding: '20px', 
        borderRadius: '8px',
        marginBottom: '20px'
      }}>
        <h2>Features:</h2>
        <ul style={{ lineHeight: '1.6' }}>
          <li>Track your favorite stocks in real-time</li>
          <li>Create your personal watchlist</li>
          <li>View detailed stock information</li>
        </ul>
      </div>

      <div style={{ 
        display: 'flex', 
        gap: '20px', 
        justifyContent: 'center' 
      }}>
        <Link to="/login" style={{
          padding: '10px 20px',
          backgroundColor: '#007bff',
          color: 'white',
          textDecoration: 'none',
          borderRadius: '4px'
        }}>
          Login to Get Started
        </Link>
      </div>
    </div>
  );
}

export default Home;
